import React from 'react';
import '../components/layout.scss';
import * as styles from './faq.module.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';

const FAQPage = () => (
  <Layout>
    <SEO title="FAQ" />

    <section className={styles.faq}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1">FAQ</h1>
          </div>
        </div>
      </div>
      <div className="large-container">
        <div className="row mb0">
          <div className="col-sm-12">
            <div className="card">
              <h2>Généralités</h2>
              <div>
                <p className="bold">Pourquoi travailler avec In-Print ?</p>
                <ul>
                  <li>
                    Notre mission consiste à vous proposer la solution print la
                    mieux adaptée, avec le meilleur rapport
                    qualité-prix-délai-service,
                  </li>
                  <li>
                    Notre expertise métier, combinée à une connaissance des
                    acteurs de la filière, vous garantit une vision précise des
                    contraintes liées à une grande variété de supports imprimés,
                    ce qui permet de faire des choix répondant parfaitement à
                    vos besoins,
                  </li>
                  <li>
                    Nous maîtrisons les différents procédés de la filière des
                    Arts Graphiques et nous nous tenons informés des évolutions,
                    notamment au niveau des nouvelles technologies,
                  </li>
                  <li>
                    Notre démarche permet de diminuer les coûts d'impression
                    sans amoindrir la qualité fournie.
                  </li>
                </ul>
              </div>
              <div>
                <p className="bold">Comment obtenir un devis ?</p>
                <ul>
                  <li>
                    Pour recevoir un devis le plus rapidement possible, le moyen
                    le plus efficace est de contacter notre équipe de
                    Deviseurs-Fabricants par mail sur{' '}
                    <a href="mailto:contact@oyp.fr">contact@oyp.fr</a>,
                  </li>
                  <li>
                    Si vous avez besoin d’un accompagnement personnalisé, vous
                    pouvez également nous joindre par téléphone au{' '}
                    <span className="secondary">05 61 24 65 35</span> (coût d’un
                    appel local). Nous nous ferons un plaisir de vous répondre
                    dans les délais les plus courts,
                  </li>
                  <li>
                    Vous pouvez enfin tout simplement nous transmettre une
                    demande de devis via ce site en utilisant le bouton «
                    Demande de devis » situé en haut à droite de l’écran.
                  </li>
                </ul>
              </div>
              <div>
                <p className="bold">
                  Est-il possible de commander vos produits en ligne ?
                </p>
                <p>
                  Le site In-Print n'est pas un site de commande en ligne mais
                  vous pouvez y laisser une demande de devis. Il a pour vocation
                  de guider nos clients dans le choix de leurs produits et de
                  leur présenter nos engagements quant aux services proposés.
                  Nous mettons également en avant nos valeurs et notre état
                  d’esprit car notre volonté est de pérenniser nos relations
                  commerciales avec nos clients-partenaires.
                </p>
              </div>
            </div>
            <div className="card">
              <h2>Fichiers</h2>
              <div>
                <p className="bold m0">
                  Quels conseils pour préparer mes fichiers ?
                </p>
                <p>
                  Afin de réaliser au plus vite et de manière optimale vos
                  impressions, nous vous conseillons de respecter les points
                  techniques suivants :
                </p>
                <ul>
                  <li>Travailler vos fichiers à taille réelle d'impression,</li>
                  <li>Rajouter un fond perdu de 2mm,</li>
                  <li>Rajouter 3mm de marge interne,</li>
                  <li>
                    Ne pas mettre d’informations d’impression (hirondelles,
                    traits de coupe),
                  </li>
                  <li>
                    Utiliser des fichiers image en 300 dpi (pixel/pouce) et non
                    compressés,
                  </li>
                  <li>Vectoriser vos polices de caractère,</li>
                  <li>
                    Enregistrer vos images en mode CMJN/8bits et non en RVB
                    (N.B. : CMJN = Cyan Magenta Jaune Noir, RVB = Rouge Vert
                    Bleu),
                  </li>
                  <li>
                    Aplatir les images pour toute utilisation de tracés, filtres
                    ou superpositions,
                  </li>
                  <li>
                    Enregistrer uniquement vos fichiers aux formats "JPEG",
                    "TIFF" ou "PDF",
                  </li>
                  <li>
                    Inclure si besoin les mentions obligatoires : "Ne pas jeter
                    sur la voie publique", "L'abus d'alcool est dangereux pour
                    la santé" et les coordonnées de votre imprimeur "In-Print -
                    www.in-print.net".
                  </li>
                </ul>
              </div>
              <div>
                <p className="bold">
                  Que faire si je rencontre des difficultés avec l’envoi de mes
                  fichiers et qu’il me faut utiliser un service externe ?
                </p>
                <p>
                  Lorsque vous rencontrez des difficultés lors de l’envoi de vos
                  fichiers (parfois dues à la taille par exemple), nous vous
                  recommandons d’utiliser un service externe d’envoi de
                  fichiers, tel que :{' '}
                  <a href="https://wetransfer.com/" target="_blank">
                    wetransfer.com
                  </a>
                  . Dans ce cas, nous vous remercions de nous en informer par
                  mail sur notre adresse{' '}
                  <a href="mailto:contact@oyp.fr">contact@oyp.fr</a>, en nous
                  précisant le service externe que vous utilisez, sans oublier
                  d’indiquer la référence de votre commande pour nous permettre
                  la bonne gestion de votre dossier. Merci de ne pas dépasser
                  une taille maximale de 300Mo.
                </p>
              </div>
              <div>
                <p className="bold">
                  Est-il possible d’envoyer mes fichiers dans une archive
                  compressée de type .rar ou .zip ?
                </p>
                <p className="m0">
                  Oui, cela est possible : vous pouvez regrouper vos fichiers
                  dans une seule et même archive afin de gagner du temps.
                </p>
              </div>
              <div>
                <p className="bold">Mes fichiers sont-ils contrôlés ?</p>
                <p>
                  Dès réception de vos fichiers, un contrôle gratuit est
                  effectué par notre équipe PAO. En cas d’anomalie dans les
                  fichiers et afin de ne pas retarder votre commande, nous vous
                  contactons pour vous proposer deux solutions :
                </p>
                <ul>
                  <li>
                    Soit vous effectuez vous-même les modifications nécessaires,
                  </li>
                  <li>
                    Soit notre service PAO les réalise. Cela engendre un coût
                    supplémentaire pour lequel nous vous transmettons un devis :
                    après acceptation de votre part, vous recevez un{' '}
                    <span className="bold">bon à tirer</span> numérique par
                    mail.
                  </li>
                </ul>
                <p className="m0">
                  <Link to="/contact">
                    Un conseil ? Un doute ? Contactez-nous !
                  </Link>
                </p>
              </div>
            </div>
            <div className="card">
              <h2>Livraison</h2>
              <div className="m0">
                <p className="bold">
                  Puis-je être livré en France et à l'étranger ?
                </p>
                <ul>
                  <li>
                    Nous assurons les livraisons en France métropolitaine avec
                    la possibilité d'un mode express,
                  </li>
                  <li>
                    Pour la Corse, les DOM-TOM ainsi que pour l'étranger, nous
                    étudions la faisabilité et le coût de la livraison au cas
                    par cas.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default FAQPage;
